import React, { useState, useCallback, FormEventHandler } from "react";
import { useDropzone } from "react-dropzone";

import { csvParse, autoType, DSVParsedArray } from "d3";
import { kml } from "@tmcw/togeojson";
import { wktToGeoJSON } from "@terraformer/wkt";
import { groupBy } from "lodash-es";
import { nanoid } from "@reduxjs/toolkit";
import * as shapefile from "shapefile";

import * as GeoJSON from "../../lib/geo/formats/geojson";

import { GeoJSONInfo, getGeoJSONInfo, getStyle, useLayerGeoJSON } from "../fetchGeoJSON";
import type { Feature, Point, Geometry } from "geojson";
import { GeoJSONFeature } from "ol/format/GeoJSON";
import { DeepPartial, isDefined } from "../../lib/utils";
import InfoDiv from "../InfoDiv";
import { getExtension } from "./utils";
import Button from "../base/Button";
import { unzip } from "src/lib/unzip";

function writePointFeature(
  feature: Record<string, any>,
  columns: ColumnMappingType
): Feature<Point> {
  const {
    [columns.lon!]: lon,
    [columns.lat!]: lat,
    [columns.height!]: height,
    ...properties
  } = feature;
  const coordinates = [+lon, +lat];
  if (height) {
    coordinates.push(+height);
  }
  return {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates,
    },
    properties,
  };
}

function writeFeatures(
  feature: Record<string, any>,
  columns: ColumnMappingType
): Feature {
  const { [columns.wkt!]: wkt, ...properties } = feature;
  return {
    type: "Feature",
    geometry: wktToGeoJSON(wkt) as Geometry,
    properties,
  };
}

const findCaseInsensitiveMatch = (arr: string[], str: string) =>
  arr.find((i) => i && i.toLowerCase() === str);

type ColumnMappingType = {
  lat?: string;
  lon?: string;
  height?: string;
  wkt?: string;
};

const getCaseInsensitiveGeometryFields = (
  arr: DSVParsedArray<Record<string, any>>
): ColumnMappingType => ({
  lat: findCaseInsensitiveMatch(arr.columns, "lat"),
  lon: findCaseInsensitiveMatch(arr.columns, "lon"),
  height: findCaseInsensitiveMatch(arr.columns, "height"),
  wkt: findCaseInsensitiveMatch(arr.columns, "wkt"),
});

async function shpZipToGeoJSON(zip: ArrayBuffer) {
  // prepare to peek into archive

  // modified from https://github.com/placemark/placemark copyright by placemark and freely available under MIT
  const unzipped = await unzip(zip);
  const fileNames = Object.keys(unzipped);
  const byExt = groupBy(fileNames, (filename) =>
    getExtension(filename).replace(/^\./, "")
  );
  const { shp = [], cpg = [], shx = [], prj = [], dbf = [] } = byExt;

  function shortest(types: string[]) {
    if (!types.length) return undefined;
    const name = types.sort((a, b) => a.length - b.length)[0];
    return {
      content: unzipped[name].buffer,
    };
  }


  const obj = {
    shp: shortest(shp),
    dbf: shortest(dbf),
    // currently unused by importer
    //shx: shortest(shx),
    //cpg: shortestString(cpg),
    //prj: shortestString(prj),
  };

  if (obj.shp) {
    // @ts-ignore - ignore SharedArrayBuffer errors for now
    const geojson = await shapefile.read(obj?.shp?.content, obj?.dbf?.content);
    return geojson;
  }
}
function csvToGeojson(csv: string) {
  const arr: DSVParsedArray<Record<string, any>> = csvParse(csv, autoType);
  const columns = getCaseInsensitiveGeometryFields(arr);
  let features: Feature[] = [];
  if (arr[0] && arr[0][columns.lat!]) {
    features = arr.map((i) => writePointFeature(i, columns)).filter((i) => i);
  }
  if (arr[0] && arr[0][columns.wkt!]) {
    features = arr.map((i) => writeFeatures(i, columns)).filter((i) => i);
  }

  if (features && features.length) {
    return {
      type: "FeatureCollection",
      features,
    };
  }
}
export function readFileAsArrayBuffer(file: File): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = function (e) {
      resolve(e.target?.result as any);
    };
    reader.onerror = function (e) {
      reject(e);
    };
    reader.readAsArrayBuffer(file);
  });
}
export function readFileAsText(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = function (e) {
      resolve(e.target?.result as any);
    };
    reader.onerror = function (e) {
      reject(e);
    };
    reader.readAsText(file);
  });
}


const FILE_TYPES = {
  CSV: [".csv"],
  GEOJSON: [".geojson", ".json"],
  KML: [".kml"],
  SHP: [".shp", ".zip"],
};
function detectType(name: string) {
  const ext = getExtension(name);

  for (const [type, extensions] of Object.entries(FILE_TYPES)) {
    if (extensions.includes(ext)) {
      return type;
    }
  }
  return null;
}
async function readFeaturesFromFile(file: File) {
  const type = detectType(file.name);
  const isCSV = type === "CSV";
  const isGeoJSON = type === "GEOJSON";
  const isKML = type === "KML";
  const isShp = type === "SHP";
  let geojson;

  if (isCSV || isGeoJSON || isKML) {
    const data = await readFileAsText(file);
    if (isCSV) {
      geojson = csvToGeojson(data);
    } else if (isKML) {
      const xml = new DOMParser().parseFromString(data, "text/xml");
      geojson = kml(xml);
    } else {
      geojson = GeoJSON.normalize(JSON.parse(data));
    }
  } else if (isShp) {
    const data = await readFileAsArrayBuffer(file);
    const ext = getExtension(file.name);
    if (ext === ".zip") {
      geojson = await shpZipToGeoJSON(data);
    } else if (ext === ".shp") {
      geojson = await shapefile.read(data);
    }
  } else {
    throw new Error("Invalid file type");
  }
  if (!geojson || !geojson.features) {
    throw new Error(`Failed to load ${file.name}: no features found`);
  }
  geojson.features = geojson.features.filter(
    (x: GeoJSONFeature) => !!x.geometry
  );
  return geojson;
}

async function readUrlAsFile(path: string) {
  const url = new URL(path);
  const res = await fetch(url);
  const buffer = await res.arrayBuffer();
  const filename = url.pathname.split("/").pop() ?? "";
  return new File([buffer], filename, {
    type: res.headers.get("Content-Type") || "",
  });
}
async function readFeaturesFromURL(path: string) {
  const file = await readUrlAsFile(path);
  return readFeaturesFromFile(file);
}

const voidFn: () => void = () => null;

type GeoJSONBuilderProps = {
  onSubmit: (layer: DeepPartial<QM.LayerLayer>) => void;
  onCancel: () => void;
};



export default function GeoJSONBuilder({
  onCancel = voidFn,
  onSubmit,
}: GeoJSONBuilderProps) {
  const [error, setError] = useState<any>(null);
  const [layer, setLayer] = useState<Partial<QM.LayerLayer> | null>();
  const onLoadGeoJSON = useCallback(
    (url: string, name: string, geojsonInfo: GeoJSONInfo) => {
      setError(null);
      setLayer({
        id: nanoid(5),
        opacity: 1,
        options: {
          olLayer: { declutter: false },
          cesiumLayer: { clampToGround: !geojsonInfo.hasHeight },
          searchFields: geojsonInfo.searchFields,
          canDelete: true,
        },
        url,
        style: {
          ...getStyle(),
          labelProperty: geojsonInfo.labelProperty,
        },
        projections: [],
        name,
        label: name,
        type: "vector",
        enabled: true,
        visible: true,
        defaultExtent: geojsonInfo.bbox,
      });
    },
    []
  );
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach(async (file) => {
        try {
          const geojson = await readFeaturesFromFile(file);
          const url = URL.createObjectURL(
            new Blob([JSON.stringify(geojson)], { type: "application/json" })
          );
          onLoadGeoJSON(url, file.name, getGeoJSONInfo(geojson));
        } catch (e) {
          console.error(e);
          setError(e);
          setLayer(null);
        }
      });
    },
    [onLoadGeoJSON]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/json": [".json", ".geojson"],
      "text/csv": [".csv"],
      "application/vnd.google-earth.kml+xml": [".kml"],
      "application/zip": [".zip"],
      "text/plain": [".csv", ".json", ".geojson", ".kml"],
      "application/octet-stream": [".zip", ".shp"],
    },
    useFsAccessApi: false,
    multiple: false,
  });

  const onLoadURL = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      e.preventDefault();
      const url = e.currentTarget.url.value;
      try {
        const file = await readUrlAsFile(url);
        const type = detectType(file.name);
        const geojson = await readFeaturesFromFile(file);
        let layerUrl = url;
        if (type !== "GEOJSON") {
          layerUrl = URL.createObjectURL(
            new Blob([JSON.stringify(geojson)], { type: "application/json" })
          );
        }
        onLoadGeoJSON(layerUrl, file.name, getGeoJSONInfo(geojson));
      } catch (e) {
        setError(e);
      }
    },
    [onLoadGeoJSON]
  );
  const { geojson } = useLayerGeoJSON(layer);
  return (
    <>
      <h2>Import Vector Data</h2>
      {layer && (
        <div>
          <div>Imported data for {layer.name}</div>
          <p>Contains {geojson.features.length} features</p>
          <InfoDiv>Note: Currently only handles lonlat</InfoDiv>
          <h3>Layer Name</h3>
          <input
            style={{ width: "100%", margin: "0 4px 0 0" }}
            type="text"
            placeholder="Enter name for layer"
            value={layer.label}
            onChange={(e) => setLayer({ ...layer, label: e.target.value })}
          />
          <div style={{ margin: "8px 0" }}>
            <label htmlFor="enableDeclutter">
              <input
                type="checkbox"
                name="enableDeclutter"
                id="enableDeclutter"
                checked={layer?.options?.olLayer?.declutter}
                onChange={() =>
                  setLayer({
                    ...layer,
                    options: {
                      ...layer?.options,
                      olLayer: {
                        ...layer?.options?.olLayer,
                        declutter: !layer?.options?.olLayer?.declutter,
                      },
                    },
                  })
                }
              />{" "}
              Enable declutter (useful to avoid overlapping labels)
            </label>
          </div>
        </div>
      )}
      {error && <InfoDiv type="error">{error.message}</InfoDiv>}

      {!layer && (
        <div>
          <div
            {...getRootProps()}
            className="border border-qmdark-300 rounded my-2 p-2 flex flex-col justify-center items-center cursor-pointer min-h-[100px] text-sm"
          >
            <input {...getInputProps()} />
            <p>Drag file here, or click to select files</p>
            <p>Supported Formats: GeoJSON, CSV, KML, SHP, ZIP (w/ shp)</p>
          </div>
          <div className="italic text-center">or</div>
          <div>
            <form className="flex flex-row items-center" onSubmit={onLoadURL}>
              <input
                name="url"
                type="text"
                placeholder="Enter URL"
                className="flex-1 mr-1"
              />
              <Button type="submit" size="sm">
                Load URL
              </Button>
            </form>
            <div className="text-center w-full italic text-xs">
              Must be a valid CORS-enabled URL
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-row justify-end mt-4">
        <Button variant="secondary" isQuiet onPress={onCancel}>
          Cancel
        </Button>
        <Button
          variant="cta"
          isDisabled={!isDefined(layer)}
          onPress={() => isDefined(layer) && onSubmit(layer)}
        >
          Done
        </Button>
      </div>
    </>
  );
}
