import { useAppSelector, useAppDispatch } from "src/hooks";
import { selectLayerById, actions as LayerActions } from "src/slices/layers";
import { JSONEditor } from "./JSONEditor";
import { Dialog, DialogContent, DialogTrigger } from "./base/Dialog";
import { Pencil } from "lucide-react";
import { Button as Button2 } from "src/components/base/button2";
import React from "react";

export default function LayerJSONEditor({
  entityId,
}: {
  entityId: string | number;
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const layer = useAppSelector((state) => selectLayerById(state, entityId));
  const dispatch = useAppDispatch();
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button2 size="sm" variant="outline" className="border-purple-400">
          <Pencil size={14} className="mr-1" />
          Edit
        </Button2>
      </DialogTrigger>
      <DialogContent>
        <div className="block overflow-hidden max-w-[500px]">
          <JSONEditor
            initialValue={JSON.stringify(layer, null, 2)}
            onSubmit={(val) => {
              dispatch(
                LayerActions.updateLayerEntity(entityId, JSON.parse(val))
              );
              setIsOpen(false);
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}
