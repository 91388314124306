import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Button } from "./base/button2";
import { isDefined } from "src/lib/utils";
import InfoDiv from "./InfoDiv";

type FormData = {
  value: string;
};
export function JSONEditor({
  initialValue,
  onSubmit,
  height = "500px",
}: {
  initialValue: string;
  onSubmit: (val: string) => void;
  height?: string
}) {
  const { handleSubmit, control, formState } = useForm<FormData>({
    reValidateMode: "onSubmit"
  });

  const _onSubmit: SubmitHandler<FormData> = (data) => {
    onSubmit(data.value);
  };

  return (
    <form onSubmit={handleSubmit(_onSubmit)}>
      <Controller
        name="value"
        control={control}
        defaultValue={initialValue}
        rules={{ required: true, validate: (val) => {
          try {
            JSON.parse(val)
        } catch (e) {
          return 'invalid JSON'
        }}}}
        render={({ field }) => (
          <CodeMirror
            theme="dark"
            height={height}
            {...field}
            ref={null}
            extensions={[json()]}
            onChange={(value) => {
              field.onChange(value);
            }}
          />
        )}
      />
      {formState.errors.value && <InfoDiv type="error">{formState.errors.value?.message}</InfoDiv>}
      <Button disabled={!formState.isDirty} className="mt-4" type="submit">Submit</Button>
    </form>
  );
}
